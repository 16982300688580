import React from "react"
const faqs = [
  {
    id: 1,
    question: "How expensive is Zrce?",
    answer: "Medium to Expensive.",
  },
  {
    id: 2,
    question: "How to get to Zrce?",
    answer: `By Car: Drive to the island of Pag via the A1 motorway. Follow signs to Novalja and then to Zrce Beach.
  By Bus: There are regular bus services from major Croatian cities to Novalja. From Novalja, shuttle buses run frequently to Zrce Beach.
  By Plane: The nearest airports are Zadar and Rijeka. From there, you can take a bus or rent a car to reach Zrce Beach.`,
  },
  {
    id: 3,
    question: "Do you need to pay for the entrance?",
    answer:
      "No, you don't need to pay for the entrance to the beach itself. However, clubs and events might have entrance fees.",
  },
  {
    id: 4,
    question: "How many clubs are there?",
    answer:
      "There are six main clubs: Kalypso, Euphoria, Nomad, Aquarius, Papaya, and Noa.",
  },
  {
    id: 5,
    question: "What time is it open?",
    answer: "Zrce Beach is open 24/7 from May until September.",
  },
  {
    id: 6,
    question: "Are there accommodations near?",
    answer:
      "Yes, there are a variety of accommodations ranging from hostels, apartments, to hotels in Novalja and surrounding areas.",
  },
  {
    id: 7,
    question: "What kind of events are there?",
    answer:
      "Zrce Beach is known for its summer music festivals and events featuring famous international DJs and artists.",
  },
  {
    id: 8,
    question: "Are there any age restrictions?",
    answer: "The clubs generally have an age restriction of 18+.",
  },
  {
    id: 9,
    question: "Can you rent sunbeds and umbrellas?",
    answer:
      "Yes, sunbeds and umbrellas are available for rent along the beach.",
  },
  {
    id: 10,
    question: "Is there a dress code?",
    answer:
      "While there is no strict dress code, beachwear is common during the day, and casual club attire is preferred at night.",
  },
  {
    id: 11,
    question: "Are there food and drink options available?",
    answer:
      "Yes, there are numerous bars, restaurants, and food stands offering a wide range of options.",
  },
  {
    id: 12,
    question: "Is Zrce Beach family-friendly?",
    answer:
      "Zrce Beach is primarily known for its party scene and may not be suitable for families with young children.",
  },
  {
    id: 13,
    question: "What are the water activities available?",
    answer:
      "There are various water activities such as jet skiing and banana boating.",
  },
  {
    id: 14,
    question: "Is there Wi-Fi available?",
    answer:
      'Yes, there is Wi-Fi coverage across the entire beach. The network is open and named "Zrce."',
  },
  {
    id: 15,
    question: "What is the best time to visit?",
    answer:
      "The best time to visit is during the summer months (June to August) when most events and festivals take place.",
  },
  {
    id: 16,
    question: "What type of beach is it?",
    answer:
      "Zrce Beach is a pebble beach, not sandy. This makes it unique and attractive for its clear waters and beautiful setting.",
  },
  {
    id: 17,
    question: "Is there security personnel?",
    answer:
      "Yes, Zrce Beach has security personnel on site to ensure the safety and well-being of all visitors.",
  },
  {
    id: 18,
    question: "Is there a lost and found service?",
    answer:
      "Yes, there is a lost and found located at the Salt Restaurant on Zrce Beach. If you lose any personal items, you can inquire there.",
  },
  {
    id: 19,
    question: "Is there a video surveillance?",
    answer:
      "Yes, Zrce Beach is equipped with video surveillance to enhance security and safety for all beachgoers.",
  },
  {
    id: 20,
    question: "What is the penalty for urinating on the beach?",
    answer:
      "Urinating on the beach is strictly prohibited and carries a fine of 50€.",
  },
]

// export default function Example() {
//   return (
//     <div className="bg-menublue-50">
//       <div className="mx-auto max-w-7xl divide-y divide-white px-6 py-24 sm:py-32 lg:px-0 lg:py-40">
//         <h2 className="text-2xl  xl:text-4xl font-bold leading-10 tracking-tight text-white font-primary-bold uppercase">
//           Frequently Asked Questions
//         </h2>
//         <dl className="mt-10 space-y-8 divide-y divide-white">
//           {faqs.map(faq => (
//             <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
//               <dt className="text-base font-semibold leading-7 text-white lg:col-span-5 font-primary-bold uppercase">
//                 <h3>{faq.question}</h3>
//               </dt>
//               <dd className="mt-4 lg:col-span-7 lg:mt-0">
//                 <p className="text-base leading-7 text-white font-primary-book">
//                   {faq.answer}
//                 </p>
//               </dd>
//             </div>
//           ))}
//         </dl>
//       </div>
//     </div>
//   )
// }

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-0 text-black">
        <h2 className="text-2xl lg:text-4xl font-bold leading-10 tracking-tight  font-primary-bold uppercase">
          Frequently asked questions
        </h2>
        <p className="mt-6 max-w-2xl text-base leading-7 font-primary-book">
          Have a different question and can’t find the answer you’re looking
          for? Reach out to our support team and we’ll get back to you as soon
          as we can.
        </p>
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-2 lg:gap-x-10">
            {faqs.map(faq => (
              <div
                key={faq.id}
                className="bg-gray-200 h-full rounded-xl p-4 px-8 border-2 border-black"
              >
                <dt className="text-lg font-semibold leading-7 text-black font-primary-bold uppercase">
                  <h3>{faq.question}</h3>
                </dt>
                <dd className="mt-2 text-sm leading-7 text-black font-inter">
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
