import React from "react"
import FunPng from "../images/blue-path.png"
import { StaticImage } from "gatsby-plugin-image"
import { useSpring, animated, config } from "react-spring"
import scrollTo from "gatsby-plugin-smoothscroll"
export default function HeroSection() {
  // const parallax = useParallax({
  //   speed: -10,
  // })
  const linkAnimation = useSpring({
    from: { transform: "translateY(500px)", opacity: 1 },
    to: { transform: "translateY(0)", opacity: 1 },
    delay: 400,
    config: config.default,
  })
  const imageAnimation = useSpring({
    from: { transform: "translateX(1000px)", opacity: 1 },
    to: { transform: "translateY(0)", opacity: 1 },
    delay: 200,
    config: config.default,
  })
  const linkAnimationNonDelayed = useSpring({
    from: { transform: "translateY(500px)", opacity: 1 },
    to: { transform: "translateY(0)", opacity: 1 },
    delay: 0,
    config: config.default,
  })
  // const ImageAnimation = useSpring({
  //   from: { opacity: 0 },
  //   to: { opacity: 1 },
  //   config: {
  //     duration: 1000,
  //   },
  // })
  // const H1anim = useSpring({
  //   from: { transform: "translate3d(-1000px, 0, 0)", opacity: 1 },
  //   to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
  //   config: config.stiff,
  // })
  const linkAnimationFirst = useSpring({
    from: { transform: "translate3d(400px, 0, 0)", opacity: 1 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 1400,
    config: config.wobbly,
  })
  const linkAnimationSecond = useSpring({
    from: { transform: "translate3d(400px, 0, 0)", opacity: 1 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 1200,
    config: config.wobbly,
  })

  const linkAnimationThird = useSpring({
    from: { transform: "translate3d(400px, 0, 0)", opacity: 1 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 1000,
    config: config.wobbly,
  })

  const linkAnimationFourth = useSpring({
    from: { transform: "translate3d(400px, 0, 0)", opacity: 1 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    delay: 800,
    config: config.wobbly,
  })

  const PathAnimation = useSpring({
    from: { transform: "translate3d(400px, 0, 0)", opacity: 1 },
    to: { transform: "translate3d(0, 0, 0)", opacity: 1 },
    config: config.stiff,
  })

  return (
    <div className="relative bg-gradient-radial from-purplebglight-50 to-purplebgdark-50 overflow-hidden animate-height">
      <main className="relative lg:relative pt-10 ">
        <div className="md:w-11/12  max-w-7xl mx-auto w-full pt-16 pb-0 text-center lg:py-48 lg:pt-[28vh] lg:text-left relative lg:h-[96vh]">
          <div className="trails-main overflow-hidden px-8 w-4/5 lg:w-1/2 sm:px-8 xl:pr-10 xl:pl-0">
            <h1 className="main-font lg:block trails-text text-4xl font-primary-bold mt-16 mb-16 lg:mt-0 mg:mb-0 tracking-normal text-left lg:w-full font-extrabold text-white sm:text-5xl md:text-6xl lg:text-5xl xl:text-5xl">
              <span className="text-pinkbutton-50">Zrce Beach</span> <br />{" "}
              <span>The Beat of</span> <span>Croatia’s Wild Heart!</span>
            </h1>
            <div className="mt-10 lg:flex  sm:justify-start lg:justify-start font-primary-bold lg:mb-32">
              <div className="rounded-md  animate-opacity">
                <button
                  onClick={() => scrollTo("#events", "center")}
                  className="w-3/5 scroll-smooth md:w-2/5 lg:w-full  transition-all hover:bg-yellowdark-50 flex items-center justify-center px-8 py-4 border border-transparent text-sm font-medium rounded-full text-fontpurple-50 bg-yellowdark-50  lg:bg-pinkbutton-50 md:py-3 md:text-sm md:px-8"
                >
                  Explore
                </button>
              </div>
            </div>
          </div>
          <animated.div
            style={linkAnimationNonDelayed}
            className="hidden lg:block absolute bottom-0 xl:p-4 text-white bg-black bg-opacity-20"
          >
            <p className="text-xs">
              Official Beach™ <br /> Concessioner
            </p>
          </animated.div>
          <p className="text-[8px] leading-[10px] text-left  p-4 pb-10 lg:hidden absolute -rotate-90 -right-8  bottom-0 xl:p-4 text-white bg-black bg-opacity-20">
            Official Beach™ <br /> Concessioner
          </p>
        </div>
        <animated.div
          style={imageAnimation}
          className="w-full pt-20 lg:h-[750px] lg:w-[750px] 2xl:h-[950px] 2xl:w-[950px]  flex flex-row justify-end lg:absolute lg:-bottom-10 lg:right-0"
        >
          <StaticImage
            alt="Zrce Beach from the sky"
            style={{ borderRadius: "2000px 0 0 0" }}
            imgStyle={{ borderRadius: "2000px 0 0 0" }}
            src="../images/hero-image-minified.jpg"
            loading="eager"
            imgClassName="rounded-image"
            className="animate-opacity rounded-image border-white overflow-hidden border-l-[20px] border-t-[4px] w-screen h-[100vw] lg:h-[750px] lg:w-[750px] 2xl:h-[950px] 2xl:w-[950px] object-cover"
          />
        </animated.div>
      </main>
      <animated.div style={linkAnimation} className="relative">
        <img
          loading="eager"
          className="absolute right-0 h-28 lg:h-40 -bottom-1 object-contain"
          src={FunPng}
          alt="fun logo"
        />
        <animated.p
          style={linkAnimationFirst}
          className="font-primary-heavy text-white absolute text-[24px] lg:text-4xl lg:right-[110px] right-[60px] bottom-[60px] lg:bottom-2"
        >
          FUN
        </animated.p>
        <animated.p
          style={linkAnimationSecond}
          className=" font-primary-heavy text-white absolute  text-[24px] lg:text-4xl lg:right-[110px] right-[60px] bottom-[40px] lg:bottom-10"
        >
          FUN
        </animated.p>
        <animated.p
          style={linkAnimationThird}
          className=" font-primary-heavy text-white absolute  text-[24px] lg:text-4xl lg:right-[110px] right-[60px] bottom-[20px] lg:bottom-[72px]"
        >
          FUN
        </animated.p>
        <animated.p
          style={linkAnimationFourth}
          className=" font-primary-heavy text-white absolute  text-[24px] lg:text-4xl lg:right-[110px] right-[60px] bottom-0  lg:bottom-[102px]"
        >
          FUN
        </animated.p>
      </animated.div>
    </div>
  )
}
