import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../containers/hero-section"
import Instagram from "../containers/instagram"
import ContentArea from "../dynamic-zone/content-area"
import FAQs from "../containers/FAQs"
// import { LazyLoadComponent } from "react-lazy-load-image-component"

const BlogIndex = ({ data, location }) => {
  // const siteTitle = data.site.siteMetadata?.title || `Title`
  // const posts = data.allMarkdownRemark.nodes
  // console.log(location)
  // if (posts.length === 0) {
  //   return (
  //     <Layout location={location} title={siteTitle}>
  //       <Seo title="Home" />
  //     </Layout>
  //   )
  // }

  return (
    <Layout animateOpacity location={location} title={"Zrce Beach"} fullHeader>
      <Seo
        title="Zrce Beach Festivals, Clubs, Events, and more"
        description="Zrce Beach on Pag Island is Croatia's No. 1 party destination, blending epic nightlife with chill
vibes right on the gorgeous Adriatic coast."
      />
      <HeroSection />
      <ContentArea
        events={data.allStrapiEvent.edges}
        blogs={data.allStrapiBlog.edges}
        eventsCarouselBg="white"
        eventsCarouselFontColor="#009ADE"
        bg="bg-menublue-50"
        bgGallery="bg-black"
        bgThreeColumn="bg-rednew-50"
        cardBorderColorClass="border-white-50"
        cardFontColorClass="text-white"
        color="#722F8B"
        type={"mainPage"}
        galleries={data.allStrapiGallery.nodes}
        textColor="text-purplebglight-50"
        contentTypes={data.strapiMainPage.pageContent}
        contactColor="bg-euphoriaBlack-50"
        titleColor="text-white"
        galleryCtaTextColor={"text-white"}
        galleryCtaBorderColor={"border-white"}
        // instaNodes={data.allInstaNode.edges}
      />
      <FAQs />
      {/* <CardsEvents
        type={"mainPage"}
        events={data.allStrapiEvent.edges}
        eventsCarouselBg="#FFFFFF"
        eventsCarouselFontColor="#009ADE"
        bg="bg-menublue-50"
        color="#FFFFFF"
        cardBorderColorClass="border-purplebglight-50"
        cardFontColorClass="text-purplebglight-50"
      />
      <VideoPlayer />
      <ZrceFun />
      <TwoColumnImages />
      <ThreeColumn
        title="Feel the fusion of tradition and trends on a plate in our
              restaurants, or stop by the Rollin flock to taste the difference!"
        logo
        bg={"bg-rednew-50"}
      />
      <Cabanas />
      <JetSki />
      {/* <LatestNews /> */}
      {/* <Gallery />*/}
      {/* <LazyLoadComponent> */}
      {/* <Instagram
        type={"mainPage"}
        title={"zrcebeach_official"}
        // instaNodes={data.allInstaNode.edges}
      /> */}
      {/* </LazyLoadComponent> */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    strapiMainPage {
      id
      pageContent {
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_BLUE_HEADING_SECTION {
          id
          strapi_component
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_CONTENT_BLOCK_CABANAS {
          id
          strapi_component
          text
          title
          ctaType {
            title
            link
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_CONTENT_BLOCK_SPORTS {
          id
          strapi_component
          text
          ctaType {
            title
            link
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_DAY_AND_NIGHT {
          id
          strapi_component
          clubColumn {
            image {
              localFile {
                childImageSharp {
                  id
                  gatsbyImageData(quality: 100)
                }
              }
            }
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_EVENTS {
          id
          strapi_component
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_FULL_WIDTH_VIDEO {
          id
          strapi_component
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_GALLERY_CLUB {
          id
          title
          strapi_component
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_NEWS {
          id
          strapi_component
        }
        ... on STRAPI__COMPONENT_MAIN_COMPONENTS_RESTAURANTS {
          id
          strapi_component
          title: sectionTitle
          SingleRestaurantImageGatsbyComp {
            mainImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80)
                }
              }
            }
            title
            link
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80)
                }
              }
            }
          }
        }
      }
    }
    allStrapiEvent {
      edges {
        node {
          title
          dateTimeFrom(formatString: "DD MMM")
          dateTimeTo(formatString: "DD MMM")
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 20, placeholder: DOMINANT_COLOR)
              }
            }
          }
        }
      }
    }
    allStrapiBlog {
      edges {
        node {
          slug
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
              }
            }
          }
          category {
            slug
          }
          title
          publishedAt
        }
      }
    }
    allStrapiGallery(limit: 3) {
      nodes {
        id
        title
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 10)
            }
          }
        }
      }
    }
    # allInstagramContent {
    #   edges {
    #     node {
    #       caption
    #       media_url
    #       localFile {
    #         childImageSharp {
    #           gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
    #         }
    #       }
    #       # album {
    #       #   localFile {
    #       #     childImageSharp {
    #       #       gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
    #       #     }
    #       #   }
    #       # }
    #     }
    #   }
    # }
    # allInstaNode {
    #   edges {
    #     node {
    #       id
    #       localFile {
    #         id
    #         childImageSharp {
    #           gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
    #         }
    #       }
    #     }
    #   }
    # }
  }
`
